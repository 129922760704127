import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GoogleRedirect = (props) => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
    const error = queryParameters.get("error");

    React.useEffect(() => {
        if (error !== null) {
            navigate("/dashboard/email_accounts", { state: { msg: error, status: 201 } });
        } else {
            fetch(`${REACT_APP_SERVER_URL}/google?code=${code}&App_type=xwarmup`, {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    navigate("/dashboard/email_accounts", { state: { msg: data.msg, status: data.status } });
                    console.log(data);
                })
                .catch((error) => console.log(error));
        }
    }, []);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
            <CircularProgress />
        </Box>
    );
};

export default GoogleRedirect;
