import {
  Alert,
  Box,
  Card,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
  MenuItem,
  MenuList,
  ClickAwayListener
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState, useRef, } from "react";
import { Input } from "rsuite";
import JoditEditor from "jodit-react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ErrorRounded } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { set } from "rsuite/esm/utils/dateUtils";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function WarmupTamplet() {
    const editor = useRef(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [templates, setTemplates] = useState([]);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

    const [planName, setplanName] = useState("");
    const [templateCount, setTemplateCount] = useState(null);
    const [templateopen, setTempalteOpen] = useState(false);
    // const [templateopens, setTempalteOpens] = useState('');
    const [updateButton, setupdateButton] = useState(false);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
    const [mail, setMail] = useState("");
    const [senderStore, setSenderStore] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedEmail, setSelectedEmail] = useState("");
    const [senderEmails, setSenderEmails] = useState(false);
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState("");
    const [message, setMessage] = useState("");
    const [uploadEmlFile, setUploadEmlFile] = useState(false);

  const send_mail = async () => {
    return fetch(`${REACT_APP_SERVER_URL}/senderEmailData`, {
      headers: {
        'Accept': 'application/json',
      },
      credentials: "include",
      method: 'GET',
    }).then(response => {
      // if (response.ok) {
      //   return response.json();
      // } else {
      //   throw new Error('Failed to fetch sender email data');
      // }
      return response.json();
    })
      .then(data => {
        console.log(data, 'coming data');
        console.log(data.data, 'Arrray of Object>>>>>>');
        // setEmailData(data.data)
        // setSenderStore(data.data);
        
        console.log(data.data);
        
        if (data.data && data.data.length > 0) {
          setSenderStore([...data.data, { email: "Global User" }]);
        } else {
          setSenderStore([{ email: "Global User" }]);
        }

      })
      .catch(error => {
        console.error('Error fetching sender email data:', error);
      });
  }

  useEffect(() => {
    // templateFunc();
    allTemplates();
    PlanDetialsFetch();
    send_mail();

  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEmailSelect = (email) => {
    setMail(email);
    setSelectedEmail(email);
    setSenderEmails(false);
  };

  const filteredEmails = senderStore.filter((sender) =>
    sender.email.toLowerCase().includes(searchQuery.toLowerCase())
  );


  function PlanDetialsFetch() {
    fetch(`${REACT_APP_SERVER_URL}/planDetails`, {
      headers: {
        'Accept': 'application/json',
      },
      credentials: "include",
      method: 'GET',
    }).then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        return response.json()
      }
    }).then(d => {
      // console.log(d, 'detailsPlan');

      // console.log(d.data[0].metadata.XWarmuPlanName, 'planNames')
      setplanName(d.data[0].metadata.XWarmuPlanName)


      // if (d.status === 200) {
      //   setExistData(d.data)
      //   if (d.data[0].reoccurring === true) {
      //     setChecked(true);
      //   }
      // } else if (d.status === 201) {
      //   setExistData(d.data)
      // } else {
      //   setErrorMessage(d.msg);
      //   setErrorSnackbarOpen(true);
      // }

    })

  }



  function allTemplates() {

    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptemplates?App_type=Xwarmup`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        // console.log(data.data, 'data prinint')
        setMail(data.data.mail)
        setTemplateCount(data.data.length)
        setTemplates(data.data);
      })
      .catch((error) => console.log(error));
  }

  function templateFunc() {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptemplate`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          return response.json();
        } else if (response.status === 202) {
          setLoading(false);
          console.log("token expired");
        } else {
          setLoading(false);
          console.log("some error");
        }
      })
      .then((data) => {
        setData(data.data.id);
        setName(data.data.name);
        setSubject(data.data.meta_data.subject);
        setBody(data.data.meta_data.body);
        setMail(data.data.mail);
      })
      .catch((error) => console.log(error));
  }

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleSuccessSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbarOpen(false);
  };

  const CreateWarmupFunction = () => {
    if (!name || !subject || !body) {
      setErrorSnackbarOpen(true);
      seterrorMsg('Name, subject, and body must not be empty.');
      return;
    }

    if (planName === 'Free') {
      if (templateCount >= 1) {
        setErrorSnackbarOpen(true);
        seterrorMsg('You have reached the limit, if you want to add more template(s) please upgrade your plan !!');
        return;
      }
    } else if (planName === 'Essential') {
      if (templateCount >= 2) {
        setErrorSnackbarOpen(true);
        seterrorMsg('You have reached the limit, if you want to add more template(s) please upgrade your plan !!');
        return;
      }
    }  else if (planName === 'Agency') {
      if (templateCount >= 5) {
        setErrorSnackbarOpen(true);
        seterrorMsg('You have reached the limit, if you want to add more template(s) please upgrade your plan !!');
        return;
      }
    }

    setLoading(true);

    fetch(`${REACT_APP_SERVER_URL}/warmup_templateAdd?App_type=Xwarmup`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
        name: name,
        subject: subject,
        text: body,
        mail: mail
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          emptyTemplate();
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
          allTemplates();
          emptyTemplate();
        }
        else if (d.status === 202) {
          setErrorSnackbarOpen(true);
          seterrorMsg('You have to be an admin in order to create a template, For this contact our team.')
        }
        else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));
  };




  const changeWarmFunction = () => {
    if (!name || !subject || !body) {
      setErrorSnackbarOpen(true);
      seterrorMsg('Name, subject, and body must not be empty.');
      return;
    }
    if (planName === 'Free') {
      // console.log('Free plan activated');
      setErrorSnackbarOpen(true);
      seterrorMsg('You have free plan, please Upgrade the plan to update more template(s) !!')
      return;
    }

    // setErrorSnackbarOpen(true);
    // seterrorMsg('You have to be an admin in order to update a template, For this contact our team.');
    // return;

    setLoading(true);

    fetch(`${REACT_APP_SERVER_URL}/warmuptemplateupdate`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
        name: name,
        subject: subject,
        text: body,
        mail: mail
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
          allTemplates()
        }
        else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));

  };


  const DeleteWarmupFunction = () => {

    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmup_templatedelete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
          allTemplates();
          emptyTemplate();
        } else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));
  };


  function getTemplate(index) {
    setSelectedTemplateIndex(index);
    setTempalteOpen(true);
    setupdateButton(true);
    let temp = templates;

    setName(temp[index].name);
    setSubject(temp[index].meta_data.subject);
    setBody(temp[index].meta_data.body);
    setData(temp[index].id);
    setMail(temp[index].mail)

    // let btn = document.getElementById("create");
    // btn.style.visibility = "hidden";
  }

  function emptyTemplate(index) {
    setSelectedTemplateIndex(index);
    setTempalteOpen(true);
    setName("");
    setSubject("");
    setBody("");
    setData("");
    setMail("")
    setupdateButton(false);

    // let btn = document.getElementById("create");
    // btn.style.visibility = "visible";

  }
  function cancelTemplate() {
    setTempalteOpen(false);
  }

  function handleSelectEmail() {
    setSenderEmails(true);
  }

  function closeSenderEmails() {
    setSenderEmails(false)
  };

    function handlesearchClick(event) {
        event.stopPropagation();
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setMessage("");

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFileContent(e.target.result);
            };
            reader.readAsText(selectedFile);
        }
    };

    const handleFileUpload = async () => {
        if (!file) {
            setErrorSnackbarOpen(true);
            seterrorMsg("Please select a file to upload.");
            return;
        }

        try {
            setLoading(true);
            fetch(`${REACT_APP_SERVER_URL}/uploadingEmlFile_template`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    id: data,
                    file: fileContent,
                    mail: mail,
                }),
            })
                .then((response) => {
                    // console.log(response, "getting response...");
                    return response.json();
                })
                .then(() => {
                    setLoading(false);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("File Uploaded Successfully");
                    allTemplates();
                });
        } catch (error) {
            console.log(error, "getting the error where uploading the file...");
        }
    };

    console.log(fileContent, "getting the filecontent uploaded...");

  return (
    <Box>
      <Box sx={{ float: "left", width: "20%", maxHeight: "80vh", overflowY: "auto" }}>
        <Button
          variant="contained"
          sx={{
            width: "25vh",
            marginBottom: "8px",
            marginLeft: '10px'
          }}
          onClick={emptyTemplate}
        >
          Create template
        </Button>

        {
          templates &&
          templates.map((template, index) => {
            const isSelected = index === selectedTemplateIndex;

                        return (
                            <>
                                <Card
                                    key={index}
                                    sx={{
                                        width: "25vh",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        borderRadius: "6px",
                                        marginBottom: "8px",
                                        marginTop: "15px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginLeft: "10px",
                                        backgroundColor: isSelected ? "#f0f0f0" : "white", // Change background color when selected
                                        transition: "background-color 0.3s ease", // Add transition for smooth effect
                                    }}
                                    onClick={(e) => {
                                        setUploadEmlFile(false);
                                        getTemplate(index);
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            marginTop: "5px",
                                            fontWeight: isSelected ? "bold" : "normal", // Make text bold when selected
                                        }}
                                        color={isSelected ? "primary" : "text.secondary"} // Change text color when selected
                                        gutterBottom
                                    >
                                        Template {index + 1}
                                    </Typography>
                                </Card>
                            </>
                        );
                    })}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {" "}
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Box
                            sx={{
                                color: "rgb(199, 197, 197)",
                                borderRadius: "15px",
                                minHeight: "600px",
                                width: "70%",
                                border: "1px solid #E7E7E7",
                                backgroundColor: "#f7f7f9",
                                p: 1,
                                pb: 0,
                                marginLeft: "30%",
                            }}
                        >
                            {uploadEmlFile ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        ml: 3,
                                        mt: 5,
                                        p: 3,
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                    }}
                                >
                                    <Button variant="contained" sx={{ mb: 3 }} onClick={() => setUploadEmlFile(false)}>
                                        Switch to Manual Template
                                    </Button>
                                    <Typography sx={{ fontWeight: "bold", mb: 1, textAlign: "center" }}>
                                        Upload .eml File
                                    </Typography>
                                    <UploadFileIcon
                                        style={{ fontSize: "48px", marginBottom: "10px", color: "#2196f3" }}
                                    />
                                    <input
                                        type="file"
                                        accept=".eml"
                                        onChange={handleFileChange}
                                        style={{
                                            display: "block",
                                            marginBottom: "8px",
                                            cursor: "pointer",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            padding: "6px 12px",
                                            backgroundColor: "#f9f9f9",
                                            width: "100%",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={handleFileUpload}
                                        sx={{
                                            mt: 3,
                                            alignSelf: "flex-end",
                                            padding: "10px 20px",
                                            backgroundColor: "#2196f3",
                                            "&:hover": {
                                                backgroundColor: "#1976d2",
                                            },
                                        }}
                                    >
                                        Upload
                                    </Button>
                                </Box>
                            ) : (
                                <div>
                                    <Button variant="contained" onClick={() => setUploadEmlFile(true)}>
                                        Upload .eml file
                                    </Button>
                                    <Typography>or</Typography>
                                    <>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    ml: 2,
                                                    pr: 2,
                                                    color: "text.secondary",
                                                    fontWeight: "bold", // Bold font for better readability
                                                }}
                                            >
                                                ID: {data}
                                            </Typography>
                                            <Typography sx={{ color: "text.secondary", fontWeight: "bold", mr: 1 }}>
                                                Name:
                                            </Typography>
                                            <TextField
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                variant="outlined" // Outlined variant for better look
                                                sx={{
                                                    flex: 1, // Take up remaining space
                                                    "& fieldset": {
                                                        borderColor: "#ccc", // Light grey border
                                                    },
                                                    backgroundColor: "white",
                                                    ml: 1,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    ml: 2,
                                                    pr: 2,
                                                    display: "flex",
                                                    color: "text.secondary",
                                                }}
                                            >
                                                Subject:
                                            </Typography>{" "}
                                            <TextField
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                                fullWidth
                                                sx={{
                                                    border: "none",
                                                    "& fieldset": {
                                                        border: "none",
                                                    },
                                                }}
                                            />{" "}
                                        </Box>
                                        <Box>
                                            <JoditEditor
                                                ref={editor}
                                                value={body}
                                                config={{
                                                    showCharsCounter: false,
                                                    showWordsCounter: false,
                                                    showXPathInStatusbar: false,
                                                    processPasteHTML: false,
                                                    fillEmptyParagraph: false,
                                                    height: 400,
                                                    uploader: {
                                                        insertImageAsBase64URI: true,
                                                        imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                                                        withCredentials: false,
                                                        format: "json",
                                                        method: "POST",
                                                        url: "http://localhost:3000/files",
                                                        headers: {
                                                            "Content-Type": "multipart/form-data",
                                                        },
                                                        prepareData: function (data) {
                                                            data.append("file", this.file);
                                                            return data;
                                                        },
                                                        isSuccess: function (resp) {
                                                            return !resp.error;
                                                        },
                                                        getMsg: function (resp) {
                                                            return resp.msg.join !== undefined
                                                                ? resp.msg.join(" ")
                                                                : resp.msg;
                                                        },
                                                        process: function (resp) {
                                                            return {
                                                                files: [resp.data],
                                                                path: "",
                                                                baseurl: "",
                                                                error: resp.error ? 1 : 0,
                                                                msg: resp.msg,
                                                            };
                                                        },
                                                        defaultHandlerSuccess: function (data, resp) {
                                                            const files = data.files || [];
                                                            if (files.length) {
                                                                this.selection.insertImage(files[0], null, 250);
                                                            }
                                                        },
                                                        defaultHandlerError: function (resp) {
                                                            this.events.fire("errorPopap", this.i18n(resp.msg));
                                                        },
                                                    },
                                                }}
                                                // tabIndex={1} // tabIndex of textarea
                                                onBlur={(newContent) => setBody(newContent)}
                                                onChange={(newContent) => {}}
                                            />
                                        </Box>
                                    </>
                                </div>
                            )}
                            
                            {updateButton && !uploadEmlFile ? (
                                <Button
                                    sx={{
                                        float: "right",
                                        margin: "8px",
                                        color: "green",
                                        border: "1px solid green",
                                        marginTop: "20px",
                                    }}
                                    onClick={changeWarmFunction}
                                    id="submit"
                                >
                                    Update
                                </Button>
                            ) : (
                                <div>
                                    {!uploadEmlFile && (
                                        <Button
                                            sx={{
                                                margin: "8px",
                                                float: "right",
                                                color: "green",
                                                border: "1px solid green",
                                                marginTop: "20px",
                                            }}
                                            onClick={CreateWarmupFunction}
                                            id="create"
                                        >
                                            Create
                                        </Button>
                                    )}

                                    <Box position="relative" display="inline-block">
                                        <TextField
                                            label="Select Email"
                                            value={mail}
                                            onClick={handleSelectEmail}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <ArrowDropDownCircleIcon />,
                                                readOnly: true,
                                                style: { textAlign: "center" },
                                            }}
                                            InputLabelProps={{
                                                shrink: !!selectedEmail,
                                                style: { position: "absolute", top: "-8px" },
                                            }}
                                            style={{
                                                width: "300px",
                                                margin: "8px",
                                                border: "1px solid green",
                                                borderRadius: "5px",
                                            }}
                                        />
                                        {senderEmails && (
                                            <Box
                                                position="absolute"
                                                top={-260}
                                                left={0}
                                                zIndex={1}
                                                width="100%"
                                                bgcolor="#fff"
                                                color="black"
                                                boxShadow="0 4px 8px rgba(0,0,0,0.2)"
                                                borderRadius="4px"
                                                padding="10px"
                                            >
                                                <TextField
                                                    label="Search Emails"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    value={searchQuery}
                                                    onClick={handlesearchClick}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    style={{ marginBottom: "8px" }}
                                                />
                                                <ClickAwayListener onClickAway={closeSenderEmails}>
                                                    <MenuList style={{ maxHeight: "200px", overflowY: "auto" }}>
                                                        {filteredEmails.map((sender, index) => (
                                                            <MenuItem
                                                                key={sender.email}
                                                                onClick={() => handleEmailSelect(sender.email)}
                                                                selected={selectedEmail === sender.email}
                                                            >
                                                                {sender.email}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Box>
                                        )}
                                    </Box>
                                </div>
                            )}
                            {updateButton && !uploadEmlFile && (
                                <Box>
                                    <Input
                                        value={mail ? mail : "Global User"}
                                        style={{
                                            width: "300px",
                                            padding: "10px",
                                            border: "2px solid green",
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            outline: "none",
                                            boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
                                            textAlign: "center",
                                            marginTop: "20px",
                                        }}
                                    />
                                    <Button
                                        startIcon={<DeleteIcon />}
                                        sx={{
                                            margin: "8px",
                                            float: "right",
                                            color: "red",
                                            border: "1px solid rgba(255, 115, 115, 0.93)",
                                            marginTop: "20px",
                                        }}
                                        onClick={DeleteWarmupFunction}
                                        id="delete"
                                    >
                                        Delete Template
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
                <Snackbar
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    open={errorSnackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleErrorSnackbarClose}
                >
                    <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                        {errorMsg}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    open={successSnackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSuccessSnackbarClose}
                >
                    <Alert
                        onClose={handleSuccessSnackbarClose}
                        variant="filled"
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        {successMsg}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default WarmupTamplet;